<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";
// import { FCM } from "@capacitor-community/fcm";
import Mixins from "@utils/mixins";
import EventBus from "@utils/event_bus";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { mapGetters } from "vuex";
// import { Browser } from "@capacitor/browser";

export default {
  name: "Logout",
  mixins: [Mixins.essentials],

  computed: {
    ...mapGetters(["currentUser, currentInstitution"]),
  },

  methods: {
    ...mapActions([
      "logoutUser",
      "setTokens",
      "setCurrentUser",
      "setCurrentInstitution",
    ]),

    async sendLogoutRequest() {
      const getDevice = () => import("@capacitor/device");
      const Device = (await getDevice()).Device;
      let platform = (await Device.getInfo()).platform;
      if (platform == "web") return;
      // FCM.deleteInstance()
      //   .then(() => alert(`Token deleted`))
      //   .catch((err) => console.log(err));

      var response = await this.api.call(
        this.essentials,
        this.endpoints.logoutUser,
        this.api.Methods.POST,
        {}
      );
      return response;
    },
  },
  async created() {
    GoogleAuth.signOut();
    // check if logging from a subordinate institution
    await this.sendLogoutRequest();

    // for automatically logging out from Fees Portal
    // if (JSON.parse(this.currentInstitution.features).includes(25)){
    //   var url = this.endpoints.feesPortalLink + "/logout";
    //   if (!url.includes("http")) url = "http://" + url;
    //   // window.open(url, "_newtab");
    //   await Browser.open({ url: url });
    //   await Browser.close(); 
    // }

    await this.logoutUser();
    EventBus.$emit("keep_alive__destroy_view", "Home"); // destroy Home view
    console.log("home destroyed");

    this.$router.push({ name: "LoginInstitute" });
  },
};
</script>